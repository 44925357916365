import { StyleSheet, css } from "aphrodite";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  Stack,
  TableContainer,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import errorHandler from "../../../components/core/store/errorHandler";
import { headers } from "../../../components/core/store/localStorage";
import { FilterBusca } from "../../../components/filter/FilterBusca";
import {
  CondPagamentoMock,
  FasesMock,
  RepresentantesMock,
  rowsFases,
  TecnicosMock,
} from "../../../mock/FasesMock";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Colors from "../../../components/constants/Colors";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { IconButton } from "@mui/material";
import CustomDatagrid from "../../vendas/datagrid/CustomDatagrid";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { FilterTecs } from "../../../components/filter/FilterTecs";

const currentUser = localStorage.getItem("userName");
const currentEmp = localStorage.getItem("empName");
const API_URL = localStorage.getItem("apiUrl");
const TOKEN_IVIVENDA = "NDdHdzlxZnFreG1OcHp6a3h3anB6Zz09";

export const CadastroCondPagamento = () => {
  const [head, setHead] = useState("");
  const [rows, setRows] = useState([]);
  const [title, setTitle] = useState("");
  const [textModal, setTextModal] = useState("");
  const [clienteList, setClienteList] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [text, setText] = useState("");
  const [telaInicial, setTelaInicial] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [ativo, setAtivo] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(25);

  async function getCondPagamento() {
    setLoading(true);
    setIsSearching(false);

    try {
      const response = await axios.get(
        "https://aplicacao.ivitech.com.br/ivivenda/api/v2/web/condicaopagamento",
        {
          headers: {
            Authorization: `Bearer ${TOKEN_IVIVENDA}`,
          },
        }
      );

      if (response.status === 200) {
        setRows(response.data);
        setIsSearching(true);
      } else {
        console.error("Erro na requisição:", response.statusText);
        setNotFound(true);
      }
    } catch (err) {
      console.error("Erro ao buscar dados:", err);
      errorHandler(err);
      setNotFound(true);
    } finally {
      setLoading(false);
    }
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = rows.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(rows.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const consultaCondPagamento = () => {
    getCondPagamento();
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      {telaInicial ? (
        <Box sx={{ flexGrow: 1 }}>
          <Grid wrap="wrap">
            <Grid
              item
              xs={6}
              sm={7}
              md={8}
              lg={10}
              style={{
                marginTop: "-1.6rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3
                className={css(styles.pageTitle)}
                style={{ marginRight: "4px" }}
              >
                Condições de Pagamento
              </h3>
            </Grid>
          </Grid>

          <hr
            style={{
              color: "#eee",
              marginTop: "-0.55rem",
              marginBottom: "1rem",
            }}
          />

          <FilterBusca
            text={text}
            setText={setText}
            search={consultaCondPagamento}
          />

          {loading ? (
            <Box
              width={"100%"}
              height={"45vh"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid
                item
                xs={12}
                md={12}
                textAlign={"center"}
                justifyContent={"center"}
                alignItems={"center"}
                bgcolor={"#ECF9F2"}
                borderRadius={5}
                padding={5}
                color={Colors.noResultFont}
              >
                <div display={"flex"} style={{ marginTop: "-0.5rem" }}>
                  <CircularProgress color="success" />
                </div>
                <div display={"flex"}>
                  <p
                    style={{
                      color: "#4b4b4b",
                      fontSize: 14,
                      marginBottom: "-1rem",
                    }}
                  >
                    Buscando informações
                  </p>
                </div>
              </Grid>
            </Box>
          ) : null}

          {isSearching ? (
            <>
              <Table>
                <Thead className="table_head">
                  <Tr>
                    <Th>ID</Th>
                    <Th style={{ textAlign: "left" }}>Descrição</Th>
                    <Th>Prazos</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentItems.map((result, index) => (
                    <Tr
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fff" : "#f9f9f9",
                      }}
                    >
                      <Td
                        className="table_number_cell"
                        style={{ textAlign: "center" }}
                      >
                        {result.id}
                      </Td>
                      <Td className="table_number_cell">{result.nome}</Td>
                      <Td className="table_number_cell">{result.nome}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="outlined"
                    disabled={currentPage === 1}
                    onClick={() => paginate(currentPage - 1)}
                    sx={{
                      backgroundColor: "#f8f8f8",
                      color: "#669966",
                      borderColor: "#669966",
                      "&:hover": {
                        backgroundColor: "#e8f5e9",
                        borderColor: "#558855",
                      },
                      "&:disabled": {
                        backgroundColor: "#f5f5f5",
                        color: "#aaa",
                        borderColor: "#ddd",
                      },
                    }}
                  >
                    Anterior
                  </Button>

                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (number) => (
                      <Button
                        key={number}
                        variant={
                          currentPage === number ? "contained" : "outlined"
                        }
                        onClick={() => paginate(number)}
                        sx={{
                          ...(currentPage !== number && {
                            backgroundColor: "#f8f8f8",
                            color: "#669966",
                            borderColor: "#669966",
                            "&:hover": {
                              backgroundColor: "#e8f5e9",
                              borderColor: "#558855",
                            },
                          }),
                          ...(currentPage === number && {
                            backgroundColor: "#669966",
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "#558855",
                            },
                          }),
                        }}
                      >
                        {number}
                      </Button>
                    )
                  )}

                  <Button
                    variant="outlined"
                    disabled={currentPage === totalPages}
                    onClick={() => paginate(currentPage + 1)}
                    sx={{
                      backgroundColor: "#f8f8f8",
                      color: "#669966",
                      borderColor: "#669966",
                      "&:hover": {
                        backgroundColor: "#e8f5e9",
                        borderColor: "#558855",
                      },
                      "&:disabled": {
                        backgroundColor: "#f5f5f5",
                        color: "#aaa",
                        borderColor: "#ddd",
                      },
                    }}
                  >
                    Próxima
                  </Button>
                </Stack>
              </Box>

              <Typography variant="body2" sx={{ textAlign: "center", mt: 1 }}>
                Página {currentPage} de {totalPages} | Total de itens:{" "}
                {rows.length}
              </Typography>
            </>
          ) : null}
        </Box>
      ) : null}

      {notFound ? (
        <Box
          width={"100%"}
          height={"45vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={12}
            md={12}
            height={{ md: "20%", xs: "50%" }}
            display={"inline-flex"}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"lightyellow"}
            borderRadius={5}
            padding={5}
            color={Colors.noResultFont}
          >
            <p
              style={{
                color: "#4b4b4b",
                // fontFamily: "Poppins",
                fontSize: 14,
              }}
            >
              Sua pesquisa não retornou nenhum resultado.
            </p>
          </Grid>
        </Box>
      ) : null}

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            minWidth: "300px",
            maxWidth: "800px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            {title}
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2, mb: 3 }}>
            {textModal}
          </Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "green",
              "&:hover": { backgroundColor: "darkgreen" },
            }}
            onClick={handleCloseModal}
          >
            OK
          </Button>
        </Box>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    fontFamily: "Poppins !important",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    transitionDuration: "0.2s",
  },
  btn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 6,
    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    marginTop: "1.95rem",
    transitionDuration: "0.2s",
    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
  btnDisabled: {
    backgroundColor: "#B3B3B3",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#B3B3B3",
    borderRadius: 6,
    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    marginTop: "1.95rem",
  },
  erro: {
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
    marginTop: "1rem",
    marginBottom: "-0.5rem",
  },
  topBtn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 6,
    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.5rem 0.5rem 0.5rem",
    transitionDuration: "0.2s",
    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
});
