import React, { createContext, useState } from "react";

export const OrcamentoContext = createContext();

export const OrcamentoProvider = ({ children }) => {
  const [lista, setLista] = useState([]);

  return (
    <OrcamentoContext.Provider value={{ lista, setLista }}>
      {children}
    </OrcamentoContext.Provider>
  );
};
