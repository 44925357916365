import { Button, Dialog, DialogTitle, Divider } from "@mui/material";
import { css, StyleSheet } from "aphrodite";

export const ErroModal = (props) => {
  const handleOkClick = () => {
    props.onConfirm?.(true); // Notifica que o usuário confirmou
    props.toggle(); // Fecha o modal
  };

  const handleCloseClick = () => {
    props.onConfirm?.(false); // Notifica que o usuário fechou sem confirmar
    props.toggle(); // Fecha o modal
  };

  return (
    <Dialog
      open={props.modalOpen}
      onClose={props.toggle}
      PaperProps={{
        sx: {
          width: "20%",
          maxHeight: 300,
        },
      }}
    >
      <DialogTitle className={css(styles.title)}>
        {props.titleModal}
        <Divider sx={{ marginBottom: "20px" }} />
        <p className={css(styles.text)}>{props.textModal}</p>
      </DialogTitle>
      <Divider sx={{ marginBottom: "20px" }} />
      <div className={css(styles.buttonContainer)}>
        <div style={{ display: "flex", gap: "10px" }}>
          {props.isTwoButtons ? (
            <Button
              variant="contained" // Alterado para 'contained' para ter fundo colorido
              sx={{
                backgroundColor: "#4caf50", // Verde
                color: "white", // Letras brancas
                "&:hover": {
                  backgroundColor: "#3d8b40", // Verde mais escuro no hover
                },
              }}
              onClick={handleOkClick}
            >
              Sim
            </Button>
          ) : null}
          <Button
            variant="contained" // Alterado para 'contained' para ter fundo colorido
            sx={{
              backgroundColor: "#f44336", // Vermelho
              color: "white", // Letras brancas
              "&:hover": {
                backgroundColor: "#d32f2f", // Vermelho mais escuro no hover
              },
            }}
            onClick={handleCloseClick}
          >
            {props.isTwoButtons ? "Não" : "Fechar"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

const styles = StyleSheet.create({
  title: {
    textAlign: "left",
    marginLeft: "10px",
    fontWeight: 700,
    fontSize: "25px",
    marginBottom: -5,
  },
  text: {
    textAlign: "left",
    marginLeft: "8px",
    fontWeight: "lighter",
    fontSize: "17px",
  },
  buttonContainer: {
    display: "flex",
    marginRight: "8px",
    justifyContent: "flex-end",
    marginBottom: "8%",
  },
});
