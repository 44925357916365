export const FasesMock = [
  {
    id: 1,
    descricao: "Comercial",
    ativa: 1,
  },
  {
    id: 2,
    descricao: "Design",
    ativa: 1,
  },
  {
    id: 3,
    descricao: "Enviar para o Cliente",
    ativa: 0,
  },
  {
    id: 4,
    descricao: "Revisão",
    ativa: 1,
  },
  {
    id: 5,
    descricao: "Finalização",
    ativa: 0,
  },
];

export const TecnicosMock = [
  {
    id: 1,
    nomeTecnico: "Yan",
    ativa: 1,
  },
  {
    id: 2,
    nomeTecnico: "Darlan",
    ativa: 1,
  },
  {
    id: 3,
    nomeTecnico: "José",
    ativa: 0,
  },
  {
    id: 4,
    nomeTecnico: "Gondim",
    ativa: 1,
  },
  {
    id: 5,
    nomeTecnico: "João",
    ativa: 0,
  },
  {
    id: 6,
    nomeTecnico: "Vitor",
    ativa: 1,
  },
  {
    id: 7,
    nomeTecnico: "Mikael",
    ativa: 1,
  },
];

export const ComplexidadeMock = [
  {
    id: 1,
    descricao: "Facil",
    ativa: 1,
  },
  {
    id: 2,
    descricao: "Media",
    ativa: 1,
  },
  {
    id: 3,
    descricao: "Dificil",
    ativa: 0,
  },
  {
    id: 4,
    descricao: "Hard",
    ativa: 1,
  },
  {
    id: 5,
    descricao: "Easy",
    ativa: 0,
  },
];

export const RepresentantesMock = [
  {
    id: 1,
    nomeRepresentante: "Yan Martins de Sousa",
  },
  {
    id: 2,
    nomeRepresentante: "Gondim",
  },
  {
    id: 3,
    nomeRepresentante: "Darlan",
  },
  {
    id: 4,
    nomeRepresentante: "Vitor",
  },
  {
    id: 5,
    nomeRepresentante: "Lucilene da Silva",
  },
  {
    id: 6,
    nomeRepresentante: "José Silva",
  },
];

export const CondPagamentoMock = [
  {
    id: 1,
    descricao: "Facil",
    prazos: "60 DD",
  },
  {
    id: 2,
    descricao: "Media",
    prazos: "120 DD",
  },
  {
    id: 3,
    descricao: "Dificil",
    prazos: "A VISTA",
  },
  {
    id: 4,
    descricao: "Hard",
    prazos: "30 DD",
  },
  {
    id: 5,
    descricao: "Easy",
    prazos: "150 DD",
  },
];
