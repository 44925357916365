import React from "react";
import { useEffect } from "react";
import {
  Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useMatch,
} from "react-router-dom";

import MainLayout from "./components/layout/MainLayout";

import LoginPage from "./pages/login/Login";
import CadastraSenha from "./pages/settings/CadastraSenha";
import RecuperarSenha from "./pages/settings/RecuperarSenha";
import RedefinirSenha from "./pages/settings/RedefinirSenha";

import MainPage from "./pages/main/mainPage";
import Produtos from "./pages/produtos/Produtos";
import ProdEdit from "./pages/produtos/ProdEdit";
import VendasEqp from "./pages/vendas/equipes/VendasEqp";
import VendasRef from "./pages/vendas/referencia/VendasRef";
import RefCurvaABC from "./pages/vendas/curvaABC_referencia/refCurvaABC";
import CliCurvaABC from "./pages/vendas/curvaABC_clientes/cliCurvaABC";
import MovimentoFinanceiro from "./pages/vendas/movimento_financeiro/MovimentoFinanceiro";
import Estabelecimentos from "./pages/vendas/estabelecimentos/Estabelecimentos";
import AutorizaVendas from "./pages/vendas/autorizacao_de_vendas/autorizaVendas";
import Vendedor from "./pages/vendas/vendedor/Vendedor";
import PosicaoProcessos from "./pages/producao/posicao_dos_processos/PosicaoProcessos";
import PosicaoOficinas from "./pages/producao/posicao_das_oficinas/PosicaoOficinas";
import ConfiguracoesUsuario from "./pages/settings/ConfiguracoesUsuario";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBR from "date-fns/locale/pt-BR";
import { PropostaComercial } from "./pages/orcamento/Elaboracao/PropostaComercial";
import { Acompanhamento } from "./pages/orcamento/Acompanhamento/Acompanhamento";
import { OrcamentoProvider } from "./pages/orcamento/OrcamentoContext";
import { CadastroFases } from "./pages/orcamento/Cadastros/CadastroFases";
import { CadastroTecnicos } from "./pages/orcamento/Cadastros/CadastroTecnicos";
import { CadastroComplexidade } from "./pages/orcamento/Cadastros/CadastroComplexidade";
import { CadastroRepresentante } from "./pages/orcamento/Cadastros/CadastroRepresentante";
import { CadastroCondPagamento } from "./pages/orcamento/Cadastros/CadastroCondPagamento";
import { AlocacaoTecnicos } from "./pages/orcamento/Acompanhamento/AlocacaoTecnicos";
import { ResultadoAcompanhamento } from "./pages/orcamento/Acompanhamento/ResultadoAcompanhamento";

function App() {
  const location = useLocation();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
      <OrcamentoProvider>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/recuperar-senha" element={<RecuperarSenha />} />
          <Route path="/user/cadastrar-senha" element={<CadastraSenha />} />
          <Route path="/user/redefinir-senha" element={<RedefinirSenha />} />
          <Route element={<MainLayout />}>
            <Route path="/main" element={<MainPage />} />
            <Route path="/produtos" element={<Produtos />} />
            <Route path="/produtos-editar/:codigo" element={<ProdEdit />} />
            <Route path="/vendas/equipe" element={<VendasEqp />} />
            <Route
              path="/vendas/estabelecimentos"
              element={<Estabelecimentos />}
            />
            <Route
              path="/vendas/meiopagamento"
              element={<MovimentoFinanceiro />}
            />
            <Route path="/vendas/referencia" element={<VendasRef />} />
            <Route
              path="/vendas/curvaABC-referencia"
              element={<RefCurvaABC />}
            />
            <Route path="/vendas/curvaABC-clientes" element={<CliCurvaABC />} />
            <Route path="/vendas/vendedor" element={<Vendedor />} />
            <Route path="/vendas/cadfases" element={<CadastroFases />} />
            <Route path="/vendas/cadtecnicos" element={<CadastroTecnicos />} />
            <Route
              path="/vendas/cadcomplexidade"
              element={<CadastroComplexidade />}
            />
            <Route
              path="/vendas/cadrepresentantes"
              element={<CadastroRepresentante />}
            />
            <Route
              path="/vendas/cadcondpagamento"
              element={<CadastroCondPagamento />}
            />
            <Route
              path="/vendas/propostacomercial"
              element={<PropostaComercial />}
            />
            <Route path="/vendas/acompanhamento" element={<Acompanhamento />} />
            <Route path="/vendas/alocacao" element={<AlocacaoTecnicos />} />
            <Route
              path="/vendas/resultado"
              element={<ResultadoAcompanhamento />}
            />
            <Route
              path="/vendas/autorizacao-de-vendas"
              element={<AutorizaVendas />}
            />
            <Route
              path="/producao/posicao-dos-processos"
              element={<PosicaoProcessos />}
            />
            <Route
              path="/producao/posicao-das-oficinas"
              element={<PosicaoOficinas />}
            />
            {/* <Route
          path="/configuracoes-usuario"
          element={<ConfiguracoesUsuario />}
        /> */}
          </Route>
        </Routes>
      </OrcamentoProvider>
    </LocalizationProvider>
  );
}

export default App;
