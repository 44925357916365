// Função para formatar o valor do input enquanto digita
export const formatarInputMonetario = (valor) => {
  // Remove todos os caracteres não numéricos exceto vírgula
  let apenasNumeros = valor.replace(/[^\d,]/g, "");

  // Permite apenas uma vírgula
  const partes = apenasNumeros.split(",");
  if (partes.length > 2) {
    apenasNumeros = partes[0] + "," + partes.slice(1).join("");
  }

  // Remove zeros à esquerda
  if (partes[0].length > 1) {
    partes[0] = partes[0].replace(/^0+/, "") || "0";
  }

  // Formata a parte inteira com separadores de milhar
  if (partes[0].length > 3) {
    partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  return partes.join(",");
};

// Função para formatar o valor para exibição
export const formatarParaReal = (valor) => {
  if (!valor) return "";

  // Garante que o valor está no formato correto antes de formatar
  const valorLimpo = valor.toString().replace(/[^\d,]/g, "");
  const partes = valorLimpo.split(",");

  // Formata parte inteira com separadores de milhar
  let parteInteira = partes[0].replace(/\D/g, "");
  parteInteira = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  // Trata a parte decimal
  let parteDecimal = "";
  if (partes.length > 1) {
    parteDecimal = "," + partes[1].replace(/\D/g, "").slice(0, 2);
  }

  return parteInteira + parteDecimal;
};
