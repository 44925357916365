import Select from "react-select";

import { Box, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import { StyleSheet, css } from "aphrodite";

import "../../assets/styles/muiComponents.css";
import { Label } from "@mui/icons-material";

function FilterAcomp(props) {
  const customStyles = {
    container: (base) => ({
      ...base,
      // marginTop: window.innerWidth > 600 ? "24px" : "0",
    }),
    control: (base) => ({
      ...base,
      minHeight: "36px",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#cacaca",
      fontSize: 12,
    }),
    input: (base) => ({
      ...base,
      // marginTop: -2,
      fontSize: 12,
    }),
    menuList: (base) => ({
      ...base,
      fontSize: 12,
    }),
    multiValue: (base) => ({
      ...base,
      fontSize: 12,
    }),
  };

  return (
    <Grid
      container
      spacing={2}
      style={{
        marginBottom: "1rem",
        paddingBottom: "1.5rem",
        borderBottom: "solid 0.5px #eee",
        alignItems: "flex-end",
      }}
    >
      <Grid item xs={12} sm={6} md={3} lg={2.5}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <label
            style={{
              fontWeight: "bold",
              textAlign: "left",
              fontSize: "0.875rem",
              marginBottom: "5px",
            }}
          >
            Período de Inclusão
          </label>
          <Box sx={{ display: "flex", gap: "12px" }}>
            <DatePicker
              label="Início"
              value={props.inicioInclusao}
              onChange={(newValue) => props.setInicioInclusao(newValue)}
              slotProps={{
                textField: {
                  size: "small",
                  error: false,
                  helperText: "",
                },
              }}
              sx={{
                width: "100%",
                "& .MuiInputBase-root": {
                  color: "#4b4b4b",
                  height: "36px",
                  fontSize: 13.5,
                },
                "& .MuiFormLabel-root": {
                  color: "#cacaca",
                  fontSize: 13.5,
                  zIndex: 0,
                },
              }}
            />
            <DatePicker
              label="Fim"
              value={props.fimInclusao}
              onChange={(newValue) => props.setFimInclusao(newValue)}
              slotProps={{
                textField: {
                  size: "small",
                  error: false,
                  helperText: "",
                },
              }}
              sx={{
                width: "100%",
                "& .MuiInputBase-root": {
                  color: "#4b4b4b",
                  height: "36px",
                  fontSize: 13.5,
                },
                "& .MuiFormLabel-root": {
                  color: "#cacaca",
                  fontSize: 13.5,
                  zIndex: 0,
                },
              }}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={2.5}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <label
            style={{
              fontWeight: "bold",
              textAlign: "left",
              fontSize: "0.875rem",
              marginBottom: "5px",
            }}
          >
            Período de Previsão
          </label>
          <Box sx={{ display: "flex", gap: "12px" }}>
            <DatePicker
              label="Início"
              value={props.inicioPrevisao}
              onChange={(newValue) => props.setInicioPrevisao(newValue)}
              slotProps={{
                textField: {
                  size: "small",
                  error: false,
                  helperText: "",
                  inputProps: { placeholder: "DD/MM/YYYY" },
                },
              }}
              sx={{
                width: "100%",
                "& .MuiInputBase-root": {
                  color: "#4b4b4b",
                  height: "36px",
                  fontSize: 13.5,
                },
                "& .MuiFormLabel-root": {
                  color: "#cacaca",
                  fontSize: 13.5,
                  zIndex: 0,
                },
              }}
            />
            <DatePicker
              label="Fim"
              value={props.fimPrevisao}
              onChange={(newValue) => props.setFimPrevisao(newValue)}
              slotProps={{
                textField: {
                  size: "small",
                  error: false,
                  helperText: "",
                  inputProps: { placeholder: "DD/MM/YYYY" },
                },
              }}
              sx={{
                width: "100%",
                "& .MuiInputBase-root": {
                  color: "#4b4b4b",
                  height: "36px",
                  fontSize: 13.5,
                },
                "& .MuiFormLabel-root": {
                  color: "#cacaca",
                  fontSize: 13.5,
                  zIndex: 0,
                },
              }}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} sm={10} md={6} lg={4}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={9} md={9} lg={9}>
            <Select
              name="fase"
              placeholder="Fase"
              isMulti
              options={props.faseList}
              value={props.fase}
              onChange={(ev) => props.setFase(ev)}
              styles={customStyles}
              noOptionsMessage={() => "Sem resultados"}
            />
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3}>
            <button
              className={
                props.loading ? css(styles.btnDisabled) : css(styles.btn)
              }
              onClick={() => props.mostrarGrafico()}
              style={{
                height: "36px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SearchOutlinedIcon />
            </button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = StyleSheet.create({
  input: {
    fontFamily: "Poppins !important",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    transitionDuration: "0.2s",
  },
  btn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    // marginTop: "1.21rem",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
  btnDisabled: {
    backgroundColor: "#B3B3B3",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#B3B3B3",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    // marginTop: "1.21rem",
  },
  erro: {
    // fontFamily: "Poppins",
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
    // marginTop: "1rem",
    marginBottom: "-0.5rem",
  },
});

export default FilterAcomp;
