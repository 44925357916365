import { useContext, useEffect, useState } from "react";
import { OrcamentoContext } from "../OrcamentoContext";
import { OrcamentosMock } from "../../../mock/OrcamentosMock";
import { Box, Button, Chip, CircularProgress, Grid } from "@mui/material";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { css, StyleSheet } from "aphrodite";
import Chart from "react-google-charts";
import { TecnicosMock } from "../../../mock/FasesMock";
import { FilterAlocacao } from "../../../components/filter/FilterAlocacao";

export const AlocacaoTecnicos = () => {
  const TecnicosMock = [
    { id: 1, nomeTecnico: "Yan", ativa: 1 },
    { id: 2, nomeTecnico: "Gondim", ativa: 1 },
    { id: 3, nomeTecnico: "Carlos Oliveira", ativa: 0 },
    { id: 4, nomeTecnico: "Darlan", ativa: 1 },
  ];

  const OrcamentosMock = [
    {
      id: 1,
      tecnico: "Yan",
      complexidade: "baixa",
      numeroProposta: "P001",
    },
    {
      id: 2,
      tecnico: "Yan",
      complexidade: "media",
      numeroProposta: "P002",
    },
    {
      id: 3,
      tecnico: "Gondim",
      complexidade: "alta",
      numeroProposta: "P003",
    },
    {
      id: 4,
      tecnico: "Gondim",
      complexidade: "baixa",
      numeroProposta: "P004",
    },
    {
      id: 5,
      tecnico: "Gondim",
      complexidade: "media",
      numeroProposta: "P005",
    },
    {
      id: 6,
      tecnico: "Carlos Oliveira",
      complexidade: "alta",
      numeroProposta: "P006",
    },
    {
      id: 7,
      tecnico: "Darlan",
      complexidade: "baixa",
      numeroProposta: "P007",
    },
    {
      id: 8,
      tecnico: "Darlan",
      complexidade: "alta",
      numeroProposta: "P008",
    },
  ];

  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [inicio, setInicio] = useState("");
  const [fim, setFim] = useState("");
  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [viewType, setViewType] = useState("quantidade");
  const [selectedOption, setSelectedOption] = useState("quantidade");

  const viewTypeOptions = [
    { value: "quantidade", label: "Quantidade de Propostas" },
    { value: "tempo", label: "Tempo Estimado (dias)" },
  ];

  const complexidadeMap = {
    baixa: 5, // 5 dias para baixa complexidade
    media: 10, // 10 dias para média complexidade
    alta: 20, // 20 dias para alta complexidade
  };

  const tecnicosAtivos = TecnicosMock.filter((tecnico) => tecnico.ativa === 1);

  // Processa os dados para o gráfico e tabela
  const processarDados = () => {
    setLoading(true);
    try {
      const dadosPorTecnico = OrcamentosMock.reduce((acc, proposta) => {
        if (!acc[proposta.tecnico]) {
          acc[proposta.tecnico] = {
            quantidade: 0,
            tempoTotal: 0,
            propostas: [],
          };
        }
        acc[proposta.tecnico].quantidade++;
        acc[proposta.tecnico].tempoTotal +=
          complexidadeMap[proposta.complexidade];
        acc[proposta.tecnico].propostas.push(proposta);
        return acc;
      }, {});

      // Dados do gráfico - agora mais dinâmicos
      const dadosGrafico = [
        ["Técnico", viewType === "quantidade" ? "Quantidade" : "Tempo (dias)"],
        ...tecnicosAtivos.map((tecnico) => {
          const dados = dadosPorTecnico[tecnico.nomeTecnico] || {
            quantidade: 0,
            tempoTotal: 0,
          };
          return [
            tecnico.nomeTecnico,
            viewType === "quantidade" ? dados.quantidade : dados.tempoTotal,
          ];
        }),
      ];

      // Dados da tabela (agora mostra ambas as colunas sempre)
      const dadosTabela = tecnicosAtivos.map((tecnico) => {
        const dados = dadosPorTecnico[tecnico.nomeTecnico] || {
          quantidade: 0,
          tempoTotal: 0,
          propostas: [],
        };

        return {
          id: tecnico.id,
          tecnico: tecnico.nomeTecnico,
          quantidadePropostas: dados.quantidade,
          tempoTotal: dados.tempoTotal,
          propostas: dados.propostas,
          ativo: tecnico.ativa,
        };
      });

      setChartData(dadosGrafico);
      setTableData(dadosTabela);
      setErro("");
    } catch (error) {
      setErro("Erro ao processar dados");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (trigger) {
      processarDados();
    }
  }, [viewType, trigger]);

  const mostrarGrafico = () => {
    setViewType(selectedOption); // Aplica a seleção temporária
    setTrigger(true);
    processarDados(selectedOption); // Processa com a opção selecionada
  };

  useEffect(() => {
    if (trigger) {
      processarDados(viewType);
    }
  }, [trigger]); // Removemos viewType das dependências

  const getFirstDayOfMonth = () => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1);
  };

  const getCurrentDate = () => {
    return new Date();
  };

  useEffect(() => {
    const firstDayOfMonth = getFirstDayOfMonth();
    const currentDate = getCurrentDate();
    setInicio(firstDayOfMonth);
    setFim(currentDate);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid wrap="wrap">
        <Grid item xs={12}>
          <h3 className={css(styles.pageTitle)}>Alocação de Técnicos</h3>
          <hr style={{ color: "#eee", marginBottom: "1rem" }} />
        </Grid>
      </Grid>

      <FilterAlocacao
        inicio={inicio}
        setInicio={setInicio}
        fim={fim}
        setFim={setFim}
        search={mostrarGrafico} // Função que aplica a mudança
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        viewTypeOptions={viewTypeOptions}
      />

      {trigger && (
        <>
          <div
            style={{
              margin: "20px 0",
              padding: "20px",
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
            }}
          >
            <Chart
              chartType="BarChart"
              width="100%"
              height="400px"
              data={chartData}
              options={{
                title:
                  viewType === "quantidade"
                    ? "Quantidade de Propostas por Técnico"
                    : "Tempo Estimado de Trabalho por Técnico",
                chartArea: { width: "60%" },
                hAxis: {
                  title:
                    viewType === "quantidade"
                      ? "Quantidade de Propostas"
                      : "Tempo Total (dias)",
                  minValue: 0,
                },
                vAxis: { title: "Técnicos" },
                colors: ["#669966"],
                legend: { position: "none" },
              }}
            />
          </div>

          <Table className={css(styles.table)}>
            <Thead className="table_head">
              <Tr>
                <Th>Técnico</Th>
                <Th style={{ textAlign: "center" }}>Quant. Propostas</Th>
                <Th style={{ textAlign: "center" }}>Tempo Total (dias)</Th>
                <Th>Status</Th>
                {/* <Th>Detalhes</Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {tableData.map((item, index) => (
                <Tr
                  key={item.id}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#fff" : "#f9f9f9",
                    opacity: item.ativo ? 1 : 0.6,
                  }}
                >
                  <Td>
                    {item.tecnico}
                    {!item.ativo && (
                      <span style={{ color: "#ff0000", marginLeft: "8px" }}>
                        (Inativo)
                      </span>
                    )}
                  </Td>
                  <Td style={{ textAlign: "center" }}>
                    {item.quantidadePropostas}
                  </Td>
                  <Td style={{ textAlign: "center" }}>{item.tempoTotal}</Td>
                  <Td style={{ textAlign: "center" }}>
                    {item.ativo ? (
                      <Chip label="Ativo" color="success" size="small" />
                    ) : (
                      <Chip label="Inativo" color="error" size="small" />
                    )}
                  </Td>
                  {/* <Td style={{ textAlign: "center" }}>
                    <Button
                      size="small"
                      onClick={() => console.log(item.propostas)}
                      disabled={!item.ativo}
                    >
                      Ver Propostas
                    </Button>
                  </Td> */}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </>
      )}

      {loading && (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <CircularProgress />
        </div>
      )}

      {erro && (
        <div
          style={{
            textAlign: "center",
            color: "#ff0000",
            padding: "10px",
            margin: "20px 0",
            backgroundColor: "#ffeeee",
            borderRadius: "4px",
          }}
        >
          {erro}
        </div>
      )}
    </Box>
  );
};

const styles = StyleSheet.create({
  input: {
    fontFamily: "Poppins !important",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    transitionDuration: "0.2s",
  },
  btn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 6,
    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    marginTop: "1.95rem",
    transitionDuration: "0.2s",
    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
  btnDisabled: {
    backgroundColor: "#B3B3B3",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#B3B3B3",
    borderRadius: 6,
    "@media (max-width: 375px)": {
      fontSize: 10, // Tamanho da fonte menor para telas pequenas
      padding: "0.3rem 0.5rem", // Padding ajustado se necessário
    },
  },
  erro: {
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
    marginTop: "1rem",
    marginBottom: "-0.5rem",
  },
  topBtn: {
    backgroundColor: "#669966",
    cursor: "pointer",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 6,
    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.5rem 0.5rem 0.5rem",
    transitionDuration: "0.2s",
    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
    "@media (max-width: 375px)": {
      fontSize: 10, // Tamanho da fonte menor para telas pequenas
      padding: "0.3rem 0.5rem", // Padding ajustado se necessário
    },
  },
});
