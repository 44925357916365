import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";
import { StyleSheet, css } from "aphrodite";
import AddIcon from "@mui/icons-material/Add";

export const FilterNivelComplexidade = (props) => {
  const customStyles = {
    container: (base) => ({
      ...base,
      marginTop: window.screen.width > 546 ? "0.5rem" : "",
      // paddingBottom: 1,
    }),
    placeholder: (base) => ({
      ...base,
      marginTop: -1,
      color: "#cacaca",
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
    input: (base) => ({
      ...base,
      marginTop: -1,
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
    menuList: (base) => ({
      ...base,
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
    multiValue: (base) => ({
      ...base,
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
  };
  return (
    <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
      <Grid
        container
        wrap="wrap"
        spacing={window.screen.width > 546 ? 2 : 1}
        style={{
          marginBottom: "1rem",
        }}
      >
        <Grid item xs={12} sm={7} md={6}>
          <TextField
            name="descricao"
            id="descricao"
            placeholder={"Descrição do Nível de Complexidade"}
            value={props.descricao}
            onChange={(e) => props.setDescricao(e.target.value)}
            type="search"
            size="small"
            fullWidth
            className={css(styles.input)}
            sx={{
              marginTop: "0.5rem",
              "& .MuiInputBase-root": {
                height: 38,
                fontSize: 12,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2} md={1.2} lg={0.8}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.ativo}
                onChange={(e) => props.setAtivo(e.target.checked)}
                name="ativo"
                color="primary"
                sx={{
                  color: "green", // Cor padrão do checkbox
                  "&.Mui-checked": {
                    color: "green", // Cor quando o checkbox está marcado
                  },
                  "&:hover": {
                    backgroundColor: "rgba(0, 128, 0, 0.1)", // Cor de fundo ao passar o mouse
                  },
                }}
              />
            }
            label="Ativo"
            sx={{
              marginTop: "0.5rem",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={1.5}
          lg={0.7}
          style={{
            marginTop: window.screen.width > 546 ? "-1.5rem" : "-2rem",
          }}
        >
          <button
            onClick={() => props.adicionarNivelComplexidade()}
            className={
              props.loading ? css(styles.btnDisabled) : css(styles.btn)
            }
            title="Buscar"
          >
            <AddIcon />
          </button>
        </Grid>
      </Grid>
    </Box>
  );
};

const styles = StyleSheet.create({
  input: {
    fontFamily: "Poppins !important",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    transitionDuration: "0.2s",
  },
  btn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    marginTop: "1.95rem",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
  btnDisabled: {
    backgroundColor: "#B3B3B3",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#B3B3B3",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    marginTop: "1.95rem",
  },
  erro: {
    // fontFamily: "Poppins",
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
    marginTop: "1rem",
    marginBottom: "-0.5rem",
  },
});
