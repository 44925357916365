import { Box, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { css, StyleSheet } from "aphrodite";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Select from "react-select";

export const FilterAlocacao = (props) => {
  const customStyles = {
    container: (base) => ({
      ...base,
      // marginTop: window.innerWidth > 600 ? "24px" : "0",
    }),
    control: (base) => ({
      ...base,
      minHeight: "36px",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#cacaca",
      fontSize: 12,
    }),
    input: (base) => ({
      ...base,
      // marginTop: -2,
      fontSize: 12,
    }),
    menuList: (base) => ({
      ...base,
      fontSize: 12,
    }),
    multiValue: (base) => ({
      ...base,
      fontSize: 12,
    }),
  };
  return (
    <Grid
      container
      spacing={2}
      style={{
        marginBottom: "1rem",
        paddingBottom: "1.5rem",
        borderBottom: "solid 0.5px #eee",
        alignItems: "flex-end",
      }}
    >
      <Grid item xs={12} sm={6} md={3} lg={1.2}>
        <DatePicker
          label="Início"
          value={props.inicio}
          onChange={(newValue) => props.setInicio(newValue)}
          slotProps={{
            textField: {
              size: "small",
              error: false,
              helperText: "",
            },
          }}
          sx={{
            width: "100%",
            "& .MuiInputBase-root": {
              color: "#4b4b4b",
              height: "36px",
              fontSize: 13.5,
            },
            "& .MuiFormLabel-root": {
              color: "#cacaca",
              fontSize: 13.5,
              zIndex: 0,
            },
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={1.2}>
        <DatePicker
          label="Fim"
          value={props.fim}
          onChange={(newValue) => props.setFim(newValue)}
          slotProps={{
            textField: {
              size: "small",
              error: false,
              helperText: "",
            },
          }}
          sx={{
            width: "100%",
            "& .MuiInputBase-root": {
              color: "#4b4b4b",
              height: "36px",
              fontSize: 13.5,
            },
            "& .MuiFormLabel-root": {
              color: "#cacaca",
              fontSize: 13.5,
              zIndex: 0,
            },
          }}
        />
      </Grid>

      <Grid item xs={12} sm={5} md={6.5}>
        <Select
          name="visualizacao"
          placeholder="Visualização do Gráfico"
          options={props.viewTypeOptions}
          value={props.viewTypeOptions.find(
            (option) => option.value === props.selectedOption // Usa selectedOption temporário
          )}
          onChange={(selectedOption) => {
            if (selectedOption) {
              props.setSelectedOption(selectedOption.value); // Apenas atualiza a seleção temporária
            }
          }}
          noOptionsMessage={() => "Sem resultados"}
          isDisabled={props.readOnly}
          styles={customStyles}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={2}
        md={0.8}
        style={{
          marginTop: window.screen.width > 546 ? "-1.5rem" : "-2rem",
        }}
      >
        <button
          onClick={() => props.search()}
          className={
            props.loading || props.readOnly
              ? css(styles.btnDisabled)
              : css(styles.btn)
          }
          //   disabled={props.loading || props.readOnly}
          title="Buscar"
        >
          <SearchOutlinedIcon />
        </button>
      </Grid>
    </Grid>
  );
};

const styles = StyleSheet.create({
  input: {
    fontFamily: "Poppins !important",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    transitionDuration: "0.2s",
  },
  btn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    // marginTop: "1.21rem",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
  btnDisabled: {
    backgroundColor: "#B3B3B3",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#B3B3B3",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    // marginTop: "1.21rem",
  },
  erro: {
    // fontFamily: "Poppins",
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
    // marginTop: "1rem",
    marginBottom: "-0.5rem",
  },
});
