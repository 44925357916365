export const OrcamentosMock = [
  {
    numeroProposta: 1,
    cliente: [
      { value: "L02_5936", label: "GVC | ANA LUCIA ALVES MATIAS 17049434779" },
    ],
    dataPrevisao: "2025-03-14T18:24:17.379Z",
    dataTransmissao: "2025-03-28T15:10:00.000Z",
    fase: [{ value: "design", label: "Design" }],
    itens: [
      {
        tecnico: "Yan",
        codigo: "1001",
        descricao: "ETIQUETA X1001",
        tipo: "B",
        quant: 3,
        preco: 30.5,
      },
      {
        tecnico: "Yan",
        codigo: "1002",
        descricao: "ETIQUETA X1002",
        tipo: "C",
        quant: 2,
        preco: 20,
      },
    ],
    total: 111.5,
  },
  {
    numeroProposta: 2,
    cliente: [{ value: "L03_1234", label: "GVC | ANDRÉ SOARES" }],
    dataPrevisao: "2025-04-15T12:00:00.000Z",
    dataTransmissao: "2025-04-18T14:30:00.000Z",
    fase: [{ value: "comercial", label: "Comercial" }],
    itens: [
      {
        tecnico: "Gondim",
        codigo: "1003",
        descricao: "ETIQUETA X1003",
        tipo: "A",
        quant: 5,
        preco: 50.75,
      },
      {
        tecnico: "Gondim",

        codigo: "1004",
        descricao: "ETIQUETA X1004",
        tipo: "B",
        quant: 4,
        preco: 40,
      },
    ],
    total: 413.75,
  },
  {
    numeroProposta: 3,
    cliente: [{ value: "L04_5678", label: "GVC | Bruna Larissa Martins" }],
    dataPrevisao: "2025-05-16T09:30:00.000Z",
    dataTransmissao: "2025-05-12T11:00:00.000Z",
    fase: [{ value: "gerar_proposta", label: "Gerar Proposta" }],
    itens: [
      {
        tecnico: "Gondim",

        codigo: "1005",
        descricao: "ETIQUETA X1005",
        tipo: "C",
        quant: 10,
        preco: 100,
      },
    ],
    total: 1000,
  },
  {
    numeroProposta: 4,
    cliente: [{ value: "L05_9101", label: "GVC | BRUNO ALVES" }],
    dataPrevisao: "2025-06-17T15:45:00.000Z",
    dataTransmissao: "2025-06-22T10:15:00.000Z",
    fase: [{ value: "enviar_cliente", label: "Enviar p/ Cliente" }],
    itens: [
      {
        tecnico: "Gondim",

        codigo: "1006",
        descricao: "ETIQUETA X1006",
        tipo: "D",
        quant: 7,
        preco: 70,
      },
      {
        tecnico: "Gondim",

        codigo: "1007",
        descricao: "ETIQUETA X1007",
        tipo: "E",
        quant: 3,
        preco: 30,
      },
    ],
    total: 640,
  },
  {
    numeroProposta: 5,
    cliente: [{ value: "L06_1121", label: "GVC | CLIENTE A" }],
    dataPrevisao: "2025-07-18T08:00:00.000Z",
    dataTransmissao: "2025-07-20T13:00:00.000Z",
    fase: [{ value: "design", label: "Design" }],
    itens: [
      {
        tecnico: "Gondim",

        codigo: "1008",
        descricao: "ETIQUETA X1008",
        tipo: "E",
        quant: 2,
        preco: 22.5,
      },
      {
        tecnico: "Gondim",

        codigo: "1009",
        descricao: "ETIQUETA X1009",
        tipo: "F",
        quant: 1,
        preco: 10,
      },
    ],
    total: 55,
  },
  {
    numeroProposta: 6,
    cliente: [{ value: "L07_3141", label: "GVC | CLIENTE B" }],
    dataPrevisao: "2025-08-19T14:20:00.000Z",
    dataTransmissao: "2025-08-25T16:45:00.000Z",
    fase: [{ value: "comercial", label: "Comercial" }],
    itens: [
      {
        tecnico: "Gondim",

        codigo: "1010",
        descricao: "ETIQUETA X1010",
        tipo: "F",
        quant: 8,
        preco: 80,
      },
    ],
    total: 640,
  },
  {
    numeroProposta: 7,
    cliente: [{ value: "L08_5161", label: "GVC | CLIENTE C" }],
    dataPrevisao: "2025-09-20T10:10:00.000Z",
    dataTransmissao: "2025-09-15T09:00:00.000Z",
    fase: [{ value: "gerar_proposta", label: "Gerar Proposta" }],
    itens: [
      {
        tecnico: "Darlan",

        codigo: "1011",
        descricao: "ETIQUETA X1011",
        tipo: "G",
        quant: 6,
        preco: 60,
      },
      {
        tecnico: "Darlan",

        codigo: "1012",
        descricao: "ETIQUETA X1012",
        tipo: "H",
        quant: 4,
        preco: 40,
      },
    ],
    total: 520,
  },
  {
    numeroProposta: 8,
    cliente: [{ value: "L09_7181", label: "GVC | CLIENTE D" }],
    dataPrevisao: "2025-10-21T16:30:00.000Z",
    dataTransmissao: "2025-10-25T17:15:00.000Z",
    fase: [{ value: "enviar_cliente", label: "Enviar p/ Cliente" }],
    itens: [
      {
        tecnico: "Darlan",

        codigo: "1013",
        descricao: "ETIQUETA X1013",
        tipo: "H",
        quant: 4,
        preco: 40.9,
      },
    ],
    total: 163.9,
  },
  {
    numeroProposta: 9,
    cliente: [{ value: "L10_9201", label: "GVC | Cliente de teste" }],
    dataPrevisao: "2025-11-22T11:50:00.000Z",
    dataTransmissao: "2025-11-20T14:40:00.000Z",
    fase: [{ value: "design", label: "Design" }],
    itens: [
      {
        tecnico: "Darlan",

        codigo: "1014",
        descricao: "ETIQUETA X1014",
        tipo: "I",
        quant: 9,
        preco: 90.5,
      },
    ],
    total: 814.5,
  },
  {
    numeroProposta: 10,
    cliente: [{ value: "L11_1221", label: "GVC | CLIENTE E" }],
    dataPrevisao: "2025-12-23T13:15:00.000Z",
    dataTransmissao: "2025-12-27T11:30:00.000Z",
    fase: [{ value: "comercial", label: "Comercial" }],
    itens: [
      {
        tecnico: "Darlan",

        codigo: "1015",
        descricao: "ETIQUETA X1015",
        tipo: "J",
        quant: 1,
        preco: 10.2,
      },
      {
        tecnico: "Darlan",

        codigo: "1016",
        descricao: "ETIQUETA X1017",
        tipo: "Y",
        quant: 2,
        preco: 10,
      },
    ],
    total: 30.2,
  },
];
