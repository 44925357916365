import Select from "react-select";

import { Box, Grid, InputAdornment, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import { StyleSheet, css } from "aphrodite";

import "../../assets/styles/muiComponents.css";
import { red } from "@mui/material/colors";
import { useEffect } from "react";
import {
  formatarInputMonetario,
  formatarParaReal,
} from "../../functions/functions";
import { TecnicosMock } from "../../mock/FasesMock";

function FilterOrc(props) {
  const customStyles = {
    container: (base) => ({
      ...base,
      marginTop: window.screen.width > 546 ? "0.5rem" : "",
    }),
    placeholder: (base) => ({
      ...base,
      marginTop: -1,
      color: "#cacaca",
      fontSize: 12,
    }),
    input: (base) => ({
      ...base,
      marginTop: -1,
      fontSize: 12,
      backgroundColor: props.readOnly ? "#E6E6E6" : "inherit",
    }),
    control: (base) => ({
      ...base,
      backgroundColor: props.readOnly ? "#E6E6E6" : "inherit",
    }),
    menuList: (base) => ({
      ...base,
      fontSize: 12,
    }),
    multiValue: (base) => ({
      ...base,
      fontSize: 12,
    }),
  };

  const textFieldStyles = {
    marginTop: "0.5rem",
    "& .MuiInputBase-root": {
      height: 38,
      fontSize: 12,
      backgroundColor: props.readOnly ? "#E6E6E6" : "inherit",
    },
    "& .MuiInputBase-input": {
      cursor: props.readOnly ? "not-allowed" : "text",
    },
  };

  const tecnicosAtivos = TecnicosMock.filter((tecnico) => tecnico.ativa === 1);

  return (
    <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
      <Grid
        container
        wrap="wrap"
        spacing={window.screen.width > 546 ? 2 : 1}
        style={{
          marginBottom: "1rem",
        }}
      >
        <Grid item xs={12} sm={5} md={6.5}>
          <Select
            name="clientes"
            placeholder="Cliente"
            options={props.clienteList}
            value={props.cliValue}
            onChange={(ev) => props.cliSet(ev)}
            noOptionsMessage={() => "Sem resultados"}
            isDisabled={props.readOnly}
            styles={customStyles}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <DatePicker
            label="Data de Previsão"
            sx={{
              width: "100%",
              marginTop: "0.5rem",
              "& .MuiInputBase-root": {
                color: "#646464",
                height: "2.35rem",
                fontSize: 13.5,
                backgroundColor: props.readOnly ? "#E6E6E6" : "inherit",
              },
              "& .MuiFormLabel-root": {
                color: "#646464",
                fontSize: 12,
              },
              "& .MuiInputBase-input": {
                cursor: props.readOnly ? "not-allowed" : "text",
              },
            }}
            slotProps={{
              textField: {
                size: "medium",
                error: false,
              },
            }}
            value={props.dataPrevisao || null} // Trata valores undefined/null
            onChange={(ev) => {
              props.dataPrevSet(ev); // Garante que está passando o novo valor
            }}
            disabled={props.readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Select
            name="fase"
            placeholder="Fase"
            options={props.faseList}
            value={props.faseValue}
            onChange={(ev) => props.faseSet(ev)}
            styles={customStyles}
            noOptionsMessage={() => "Sem resultados"}
            isDisabled={props.readOnly}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={1.5}>
          <TextField
            name="proposta"
            id="proposta"
            placeholder={"No. Proposta"}
            value={props.numeroProposta || ""}
            type="search"
            size="small"
            fullWidth
            className={css(styles.input)}
            sx={{
              marginTop: "0.5rem",
              "& .MuiInputBase-root": {
                height: 38,
                fontSize: 12,
                backgroundColor: "#E6E6E6",
              },
            }}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={2}>
          <Select
            name="tecnico"
            placeholder="Técnico"
            // options={props.clienteList}
            options={tecnicosAtivos.map((item) => ({
              value: item.id,
              label: item.nomeTecnico,
            }))}
            value={props.tecnico}
            onChange={(ev) => props.setTecnico(ev)}
            noOptionsMessage={() => "Sem resultados"}
            isDisabled={props.readOnly}
            styles={customStyles}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={1.7}>
          <TextField
            name="codigo"
            id="codigo"
            placeholder={"Código"}
            value={props.codigo}
            onChange={(e) => props.setCodigo(e.target.value)}
            type="search"
            size="small"
            fullWidth
            className={css(styles.input)}
            sx={textFieldStyles}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={0.8}
          style={{
            marginTop: window.screen.width > 546 ? "-1.5rem" : "-2rem",
          }}
        >
          <button
            onClick={() => props.search()}
            className={
              props.loading || props.readOnly
                ? css(styles.btnDisabled)
                : css(styles.btn)
            }
            disabled={props.loading || props.readOnly}
            title="Buscar"
          >
            <SearchOutlinedIcon />
          </button>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <TextField
            name="descricao"
            id="descricao"
            placeholder={"Descrição"}
            value={props.descricao}
            onChange={(e) => props.setDescricao(e.target.value)}
            type="search"
            size="small"
            fullWidth
            className={css(styles.input)}
            sx={textFieldStyles}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <TextField
            name="nivelComplexidade"
            id="nivelComplexidade"
            placeholder={"Nível de Complexidade"}
            value={props.tipo}
            onChange={(e) => props.setTipo(e.target.value)}
            type="search"
            size="small"
            fullWidth
            className={css(styles.input)}
            sx={textFieldStyles}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <TextField
            name="quantidade"
            id="quantidade"
            placeholder={"Quantidade"}
            value={props.quant}
            onChange={(e) => props.setQuant(e.target.value)}
            type="search"
            size="small"
            fullWidth
            className={css(styles.input)}
            sx={textFieldStyles}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <TextField
            name="preco"
            id="preco"
            placeholder={"Preço"}
            value={formatarParaReal(props.preco)}
            onChange={(e) => {
              const valorFormatado = formatarInputMonetario(e.target.value);
              props.setPreco(valorFormatado);
            }}
            type="text"
            inputMode="numeric"
            size="small"
            fullWidth
            className={css(styles.input)}
            sx={textFieldStyles}
            InputProps={{
              readOnly: props.readOnly,
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={0.8}
          style={{
            marginTop: window.screen.width > 546 ? "-1.5rem" : "-2rem",
          }}
        >
          <button
            onClick={() => !props.readOnly && props.gerar()}
            className={
              props.loading || props.readOnly
                ? css(styles.btnDisabled)
                : css(styles.btn)
            }
            title={props.readOnly ? "Edição bloqueada" : "Novo Orçamento"}
            disabled={props.loading || props.readOnly}
          >
            {props.isEditing ? <EditIcon /> : <AddIcon />}
          </button>
        </Grid>
      </Grid>
    </Box>
  );
}

const styles = StyleSheet.create({
  input: {
    fontFamily: "Poppins !important",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    transitionDuration: "0.2s",
  },
  btn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    marginTop: "1.95rem",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
  btnDisabled: {
    backgroundColor: "#B3B3B3",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#B3B3B3",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    marginTop: "1.95rem",
  },
  erro: {
    // fontFamily: "Poppins",
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
    marginTop: "1rem",
    marginBottom: "-0.5rem",
  },
});

export default FilterOrc;
