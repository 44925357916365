import FilterEst from "../../../components/filter/FilterEst";
import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import CustomDatagrid from "../../vendas/datagrid/CustomDatagrid";
import {
  Box,
  Grid,
  Pagination,
  PaginationItem,
  Button,
  TextField,
  IconButton,
  Divider,
  Stack,
  Tooltip,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableViewIcon from "@mui/icons-material/TableView";
import { StyleSheet, css } from "aphrodite";
import Colors from "../../../components/constants/Colors";
import { headers } from "../../../components/core/store/localStorage";
import errorHandler from "../../../components/core/store/errorHandler";
import FilterOrc from "../../../components/filter/FilterOrc";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { OrcamentosMock } from "../../../mock/OrcamentosMock";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { OrcamentoContext } from "../OrcamentoContext";
import EditIcon from "@mui/icons-material/Edit";
import { ErroModal } from "../../../components/modals/ErroModal";
import { set } from "date-fns";

const currentUser = localStorage.getItem("userName");
const currentEmp = localStorage.getItem("empName");
const API_URL = localStorage.getItem("apiUrl");

export const PropostaComercial = () => {
  const dt = new Date();
  const { lista, setLista } = useContext(OrcamentoContext);
  const [loading, setLoading] = useState(false);
  const [isTwoButtons, setIsTwoButtons] = useState(false);
  const [modalResponse, setModalResponse] = useState(null);
  const [cliente, setCliente] = useState("");
  const [tecnico, setTecnico] = useState([]);
  const [trigger, setTrigger] = useState(false);
  const [head, setHead] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [textModal, setTextModal] = useState("");
  const [erro, setErro] = useState("");
  const [clienteList, setClienteList] = useState([]);
  const [dataPrevisao, setDataPrevisao] = useState(dt);
  const [fase, setFase] = useState("");
  const [codigo, setCodigo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [propostaSelecionada, setPropostaSelecionada] = useState(null);
  const [tipo, setTipo] = useState("");
  const [quant, setQuant] = useState("");
  const [preco, setPreco] = useState("");
  const [orcamentoList, setOrcamentoList] = useState([]);
  const [showSelectedOrc, setShowSelectedOrc] = useState(false);
  const [orcamentoSelecionado, setOrcamentoSelecionado] = useState(null);
  const [numeroProposta, setNumeroProposta] = useState("");
  const [showAllOrcs, setShowAllOrcs] = useState(false);
  const [subtotalPedido, setSubtotalPedido] = useState(0);
  const [mostrarNumeroProposta, setMostrarNumeroProposta] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [itemEditando, setItemEditando] = useState(null); // Adicione este estado
  const [readOnly, setReadOnly] = useState(true);

  // STATES PRO MOCK
  const [orcamentos, setOrcamentos] = useState(OrcamentosMock);
  const [faseList, setFaseList] = useState([
    { value: "comercial", label: "Comercial" },
    { value: "design", label: "Design" },
    { value: "gerar_proposta", label: "Gerar Proposta" },
    { value: "enviar_cliente", label: "Enviar p/ Cliente" },
  ]);

  useEffect(() => {
    getClientes();
    if (API_URL === null) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (trigger && orcamentoList.length === 0) {
      setErro("Nenhum orçamento gerado.");
    } else {
      setErro("");
    }
  }, [orcamentoList, trigger]);

  // FAZER A REQUISIÇÃO DEPOIS
  const openAllOrc = () => {
    setLoading(true);
    if (orcamentos) setLoading(false);
    setReadOnly(true);
    setCliente("");
    setFase("");
    setTecnico("");
    setNumeroProposta("");
    setCodigo("");
    setDescricao("");
    setTipo("");
    setQuant("");
    setPreco("");
    if (OrcamentosMock.length < 1) {
      setErro("Nenhum orçamento gerado.");
    } else {
      setErro("");
      setShowAllOrcs(true);
    }
    setTrigger(false);
    setShowSelectedOrc(false);
    setOrcamentoSelecionado(null);
  };

  const handleViewOrc = (numeroProposta) => {
    if (propostaSelecionada === numeroProposta && showSelectedOrc) {
      setPropostaSelecionada(null);
      setShowSelectedOrc(false);
      setShowAllOrcs(true);
      return;
    }

    let propostaAtual = OrcamentosMock.find(
      (proposta) => proposta.numeroProposta === numeroProposta
    );

    if (propostaAtual) {
      const clienteObj = propostaAtual.cliente[0];
      setCliente({ value: clienteObj.value, label: clienteObj.label });

      const faseObj = propostaAtual.fase[0];
      setFase({ value: faseObj.value, label: faseObj.label });

      setNumeroProposta(propostaAtual.numeroProposta);
    }

    setPropostaSelecionada(numeroProposta);
    setShowSelectedOrc(true);
    setShowAllOrcs(false);
    setReadOnly(true);
    setErro("");
    setOrcamentoList([]);
    setTrigger(false);
  };

  const handleRemoveRowFromTable = (propostaIndex, itemIndex) => {
    setIsTwoButtons(true);
    setModalOpen(true);
    setTitleModal("Excluir Linha");
    setTextModal("Tem certeza que deseja excluir essa linha?");

    const handleModalConfirm = (response) => {
      if (response) {
        // Se for "OK"
        const novaOrcamentoList = [...orcamentoList];
        novaOrcamentoList[propostaIndex].itens.splice(itemIndex, 1);

        if (novaOrcamentoList[propostaIndex].itens.length === 0) {
          novaOrcamentoList.splice(propostaIndex, 1);
        }

        setOrcamentoList(novaOrcamentoList);

        const novaLista = lista.filter((item, index) => {
          return true;
        });
        setLista(novaLista);

        if (novaOrcamentoList.length === 0) {
          setCliente("");
          setDataPrevisao(dt);
          setFase("");
          setTecnico("");
          setCodigo("");
          setNumeroProposta("");
          setDescricao("");
          setTipo("");
          setQuant("");
          setPreco("");
          setReadOnly(true);
          setErro("Nenhum orçamento gerado.");
          setTrigger(false);
        }
      } else {
        // Se o usuário fechou ou clicou "Fechar", o fluxo do código não é alterado
      }
    };

    // Passa a função de callback corretamente
    setModalResponse(() => handleModalConfirm);
  };

  const handleDeleteOrcamento = (numeroProposta) => {
    setIsTwoButtons(true);
    setModalOpen(true);
    setTitleModal("Excluir Proposta");
    setTextModal("Tem certeza que deseja excluir essa Proposta?");

    const handleModalConfirm = (response) => {
      if (response) {
        const novaLista = orcamentos.filter(
          (proposta) => proposta.numeroProposta !== numeroProposta
        );
        setOrcamentos(novaLista);
      } else {
      }
    };
    setModalResponse(() => handleModalConfirm);
  };

  const gerarNovaProposta = () => {
    setOrcamentoList([]);
    setReadOnly(false);
    setShowAllOrcs(false);
    setShowSelectedOrc(false);
    setCliente("");
    setFase("");
    setNumeroProposta("");
    setTecnico("");
    setTrigger(false);
    setIsEditing(false);
    setItemEditando(null);
    setPropostaSelecionada(null);
  };

  const propostaAtual = OrcamentosMock.find(
    (proposta) => proposta.numeroProposta === propostaSelecionada
  );

  const updateCliente = (e) => setCliente(e);
  const updateDataPrev = (e) => setDataPrevisao(e);
  const updateFase = (e) => setFase(e);

  const gerarOrcamento = () => {
    if (showSelectedOrc) return;

    const tecnicoPreenchido = tecnico && (tecnico.label || tecnico);

    if (
      !cliente ||
      !dataPrevisao ||
      !fase ||
      !tecnico ||
      !codigo ||
      !descricao ||
      !tipo ||
      !quant
    ) {
      setIsTwoButtons(false);
      setTitleModal("Erro ao gerar proposta");
      setTextModal("Preencha todos os campos obrigatórios.");
      setModalOpen(true);
      return;
    }

    const dataAtual = new Date().setHours(0, 0, 0, 0);
    const dataPrevisaoSemHora = new Date(dataPrevisao).setHours(0, 0, 0, 0);

    if (dataPrevisaoSemHora < dataAtual) {
      setErro("Data de previsão não pode ser menor que data atual");
      return;
    }

    setMostrarNumeroProposta(true);

    let novoNumeroProposta = numeroProposta;
    if (!trigger) {
      const ultimoNumeroProposta =
        lista.length > 0
          ? Math.max(...lista.map((orc) => orc.numeroProposta))
          : 0;
      novoNumeroProposta = ultimoNumeroProposta + 1;
      setNumeroProposta(novoNumeroProposta);
    }

    const parsePreco = (precoStr) => {
      if (!precoStr) return 0;

      const valorNumerico = precoStr.replace(/\./g, "").replace(",", ".");

      const numero = parseFloat(valorNumerico);
      return isNaN(numero) ? 0 : numero;
    };

    const novoItem = {
      codigo: codigo,
      descricao: descricao,
      tipo: tipo,
      quant: parseFloat(quant) || 0,
      preco: parsePreco(preco),
      tecnico: tecnico.label || tecnico,
    };

    if (isEditing && itemEditando) {
      const novaOrcamentoList = [...orcamentoList];

      if (itemEditando.propostaIndex === -1 && propostaAtual) {
        const itensAtualizados = [...propostaAtual.itens];
        itensAtualizados[itemEditando.index] = novoItem;
        const novaOrcamentos = orcamentos.map((p) =>
          p.numeroProposta === propostaAtual.numeroProposta
            ? { ...p, itens: itensAtualizados }
            : p
        );
        setOrcamentos(novaOrcamentos);
      } else {
        novaOrcamentoList[itemEditando.propostaIndex].itens[
          itemEditando.index
        ] = novoItem;
        setOrcamentoList(novaOrcamentoList);
      }

      setIsEditing(false);
      setItemEditando(null);
    } else {
      let novoNumeroProposta = numeroProposta;
      if (!trigger) {
        const ultimoNumeroProposta =
          lista.length > 0
            ? Math.max(...lista.map((orc) => orc.numeroProposta))
            : 0;
        novoNumeroProposta = ultimoNumeroProposta + 1;
        setNumeroProposta(novoNumeroProposta);
      }

      const clienteValue =
        typeof cliente === "object" ? cliente.label : cliente;
      const faseValue = typeof fase === "object" ? fase.label : fase;

      const propostaExistenteIndex = orcamentoList.findIndex(
        (proposta) => proposta.numeroProposta === novoNumeroProposta
      );

      if (propostaExistenteIndex >= 0) {
        const propostaAtualizada = {
          ...orcamentoList[propostaExistenteIndex],
          itens: [...orcamentoList[propostaExistenteIndex].itens, novoItem],
        };
        const novaLista = [...orcamentoList];
        novaLista[propostaExistenteIndex] = propostaAtualizada;
        setOrcamentoList(novaLista);
      } else {
        const novaProposta = {
          numeroProposta: novoNumeroProposta,
          cliente: clienteValue,
          dataPrevisao: dataPrevisao,
          fase: faseValue,
          itens: [novoItem],
        };
        setOrcamentoList([...orcamentoList, novaProposta]);
      }
    }

    setCodigo("");
    setDescricao("");
    setTecnico("");
    setTipo("");
    setQuant("");
    setPreco("");
    setErro("");
    setTrigger(true);
  };

  const procurarOrcamento = () => {
    // alert("procurando");
  };

  const calcularSubtotal = () => {
    return orcamentoList.reduce((totalPropostas, proposta) => {
      const subtotalProposta = proposta.itens.reduce((totalItens, item) => {
        return totalItens + parseFloat(item.quant) * parseFloat(item.preco);
      }, 0);
      return totalPropostas + subtotalProposta;
    }, 0);
  };

  const getClientes = () => {
    axios
      .get(`${API_URL}/cliente`, headers())
      .then((response) => {
        const resp = response.data.clientes.map((res) => ({
          value: res.id,
          label: res.estabelecimentoId + " | " + res.nome,
        }));
        setClienteList(resp);
      })
      .catch((err) => {
        console.log(err);
        errorHandler(err);
      });
  };

  const propostasUnicas = lista.reduce((acc, orcamento) => {
    const propostaExistente = acc.find(
      (p) => p.numeroProposta === orcamento.numeroProposta
    );

    if (!propostaExistente) {
      acc.push(orcamento);
    }

    return acc;
  }, []);

  const closeModal = () => {
    setModalOpen(false);
  };

  const transmitirProposta = () => {
    if (orcamentoList.length === 0) {
      setErro("Nenhum orçamento para transmitir");
      return;
    }

    const primeiroOrcamento = orcamentoList[0];

    const dadosParaEnvio = {
      numeroProposta: primeiroOrcamento.numeroProposta,
      cliente: primeiroOrcamento.cliente,
      dataPrevisao: primeiroOrcamento.dataPrevisao,
      fase: primeiroOrcamento.fase,
      tecnico: tecnico.label,
      itens: primeiroOrcamento.itens.map((item) => ({
        codigo: item.codigo,
        descricao: item.descricao,
        tipo: item.tipo,
        quantidade: item.quant,
        precoUnitario: item.preco.toFixed(2),
      })),
      subtotal: primeiroOrcamento.itens
        .reduce(
          (total, item) =>
            total + parseFloat(item.quant) * parseFloat(item.preco),
          0
        )
        .toFixed(2),
      dataTransmissao: new Date().toISOString(),
      usuario: "usuario_logado", // Substituir pelo usuário real
    };

    /*
    // Exemplo de requisição POST comentada
    axios.post(`${API_URL}/propostas`, dadosParaEnvio, headers())
      .then(response => {
        alert('Proposta transmitida com sucesso!');
        console.log('Resposta do servidor:', response.data);
        
        // Limpa os estados após transmissão bem-sucedida
        setOrcamentoList([]);
        setTrigger(false);
        setErro('');
      })
      .catch(error => {
        console.error('Erro ao transmitir proposta:', error);
        setErro('Erro ao transmitir proposta. Por favor, tente novamente.');
        
        if (error.response) {
          console.error('Detalhes do erro:', error.response.data);
        }
      });
    */

    console.log(
      "Dados que seriam enviados:",
      JSON.stringify(dadosParaEnvio, null, 2)
    );
    console.log(dadosParaEnvio.itens[0].codigo);

    setOrcamentoList([]);
    setTrigger(false);
    setReadOnly(true);
    setCliente("");
    setTecnico("");
    setFase("");
    setNumeroProposta("");
    setErro("");
  };

  const editarLinhaOrcamento = (index) => {
    setReadOnly(false);
    setIsEditing(true);

    const getItemPreco = (preco) => {
      if (typeof preco === "string") {
        return preco.includes(",") ? preco : `${preco},00`;
      }
      return preco.toFixed(2).replace(".", ",");
    };

    const normalizeTecnico = (tecnico) => {
      if (!tecnico) return null;
      if (typeof tecnico === "object" && tecnico.value && tecnico.label)
        return tecnico;
      return { value: tecnico, label: tecnico };
    };

    if (!propostaSelecionada && orcamentoList.length > 0) {
      const item = orcamentoList[0].itens[index];
      setItemEditando({ index, propostaIndex: 0 });
      setTecnico(normalizeTecnico(item.tecnico));
      setCodigo(item.codigo);
      setDescricao(item.descricao);
      setTipo(item.tipo);
      setQuant(item.quant.toString());
      setPreco(getItemPreco(item.preco));
    } else if (propostaAtual) {
      const item = propostaAtual.itens[index];
      setItemEditando({ index, propostaIndex: -1 });
      setTecnico(normalizeTecnico(item.tecnico)); // Usa a função de normalização
      setCodigo(item.codigo);
      setDescricao(item.descricao);
      setTipo(item.tipo);
      setQuant(item.quant.toString());
      setPreco(getItemPreco(item.preco));
    }
  };

  const cancelarProposta = () => {
    setReadOnly(true);
    setTrigger(false);
    setShowAllOrcs(false);
    setShowSelectedOrc(false);
    setCliente("");
    setDataPrevisao(dt);
    setFase("");
    setNumeroProposta("");
    setTecnico("");
    setCodigo("");
    setDescricao("");
    setTipo("");
    setQuant("");
    setPreco("");
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid wrap="wrap">
          <Grid
            item
            xs={6}
            sm={7}
            md={8}
            lg={10}
            style={{
              marginTop: "-1.6rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 className={css(styles.pageTitle)}>Proposta</h3>
            <div style={{ display: "flex", gap: "15px" }}>
              <button
                disabled={!readOnly}
                onClick={gerarNovaProposta}
                className={
                  readOnly ? css(styles.topBtn) : css(styles.btnDisabled)
                }
                title="Nova Proposta"
              >
                Nova Proposta
              </button>
              <button onClick={openAllOrc} className={css(styles.topBtn)}>
                Listagem
              </button>
            </div>
          </Grid>
        </Grid>

        <hr
          style={{ color: "#eee", marginTop: "-0.55rem", marginBottom: "1rem" }}
        />

        <FilterOrc
          tecnico={tecnico}
          setTecnico={setTecnico}
          readOnly={readOnly}
          setReadOnly={setReadOnly}
          dataPrevisao={dataPrevisao}
          numeroProposta={numeroProposta ? numeroProposta : ""}
          dataPrevSet={updateDataPrev}
          loading={loading}
          cliValue={cliente}
          cliSet={updateCliente}
          clienteList={clienteList}
          erro={erro}
          gerar={gerarOrcamento}
          search={procurarOrcamento}
          faseValue={fase}
          faseList={faseList}
          faseSet={updateFase}
          codigo={codigo}
          setCodigo={setCodigo}
          descricao={descricao}
          setDescricao={setDescricao}
          tipo={tipo}
          setTipo={setTipo}
          quant={quant}
          setQuant={setQuant}
          preco={preco}
          setPreco={setPreco}
          isEditing={isEditing}
        />

        <Divider sx={{ marginTop: "25px", marginBottom: "25px" }} />

        {trigger ? (
          orcamentoList.length > 0 ? (
            <Box>
              <Card sx={{ boxShadow: 2, marginBottom: "25px", padding: "2px" }}>
                <CardContent
                  sx={{
                    mt: -0.5,
                    padding: 0.5,
                    "&:last-child": {
                      paddingBottom: 0.25,
                    },
                  }}
                >
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <Typography variant="body2" fontWeight={"Bold"}>
                      Total de itens:{" "}
                      {orcamentoList.reduce(
                        (total, proposta) => total + proposta.itens.length,
                        0
                      )}
                    </Typography>
                    <Typography variant="body2" fontWeight={"Bold"}>
                      Subtotal do pedido: R${" "}
                      {calcularSubtotal().toFixed(2).replace(".", ",")}
                    </Typography>
                  </Stack>

                  <Stack
                    direction={"row"}
                    justifyContent={"left"}
                    alignItems={"left"}
                    width={"100%"}
                    style={{ marginTop: 10 }}
                  >
                    <Typography variant="body2" fontWeight={"Bold"}>
                      Quantidade:{" "}
                      {orcamentoList.reduce(
                        (total, proposta) =>
                          total +
                          proposta.itens.reduce(
                            (sum, item) => sum + parseFloat(item.quant || "0"),
                            0
                          ),
                        0
                      )}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>

              {orcamentoList.map((proposta, propostaIndex) => (
                <div
                  key={proposta.numeroProposta}
                  style={{ marginBottom: "30px" }}
                >
                  <Table className={css(styles.table)}>
                    <Thead className="table_head">
                      <Tr>
                        <Th>Técnico</Th>
                        <Th>Código</Th>
                        <Th>Descrição</Th>
                        <Th>Tipo</Th>
                        <Th>Quant.</Th>
                        <Th>Preço</Th>
                        <Th>Subtotal</Th>
                        <Th>Ações</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {proposta.itens.map((item, itemIndex) => (
                        <Tr
                          key={itemIndex}
                          style={{
                            backgroundColor:
                              itemIndex % 2 === 0 ? "#fff" : "#f9f9f9",
                          }}
                        >
                          <Td className="table_number_cell">{item.tecnico}</Td>
                          <Td className="table_number_cell">{item.codigo}</Td>
                          <Td className="table_number_cell">
                            {item.descricao}
                          </Td>
                          <Td className="table_actions">{item.tipo}</Td>
                          <Td style={{ textAlign: "right" }}>{item.quant}</Td>
                          <Td style={{ textAlign: "right" }}>
                            R${" "}
                            {parseFloat(item.preco)
                              .toFixed(2)
                              .replace(".", ",")
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </Td>
                          <Td style={{ textAlign: "right" }}>
                            R${" "}
                            {parseFloat(item.preco * item.quant)
                              .toFixed(2)
                              .replace(".", ",")
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                          </Td>
                          <Td
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "15px",
                            }}
                          >
                            <Tooltip title={"Editar"} arrow>
                              <EditIcon
                                color="action"
                                fontSize="inherit"
                                onClick={() => editarLinhaOrcamento(itemIndex)}
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                            <Tooltip title={"Excluir"} arrow>
                              <DeleteIcon
                                color="error"
                                fontSize="inherit"
                                onClick={() =>
                                  handleRemoveRowFromTable(
                                    propostaIndex,
                                    itemIndex
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </Tooltip>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </div>
              ))}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={transmitirProposta}
                  className={css(styles.topBtn)}
                >
                  Gerar Proposta
                </button>
                <button
                  onClick={cancelarProposta}
                  className={css(styles.topBtn)}
                >
                  Cancelar
                </button>
              </div>
            </Box>
          ) : (
            <div style={{ textAlign: "center" }}>Nenhum orçamento gerado.</div>
          )
        ) : (
          <div style={{ textAlign: "center" }}>{erro}</div>
        )}

        {showAllOrcs ? (
          <Table className={css(styles.table)}>
            <Thead className="table_head">
              <Tr>
                <Th>Nº Proposta</Th>
                <Th style={{ textAlign: "left" }}>Cliente</Th>
                <Th style={{ textAlign: "left" }}>Data Previsão</Th>
                <Th style={{ textAlign: "left" }}>Fase</Th>
                <Th>Total</Th>
                <Th>Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {orcamentos.map((proposta, index) => (
                <React.Fragment key={index}>
                  <Tr
                    className="table"
                    style={{
                      backgroundColor: index % 2 === 0 ? "#fff" : "#f9f9f9",
                    }}
                  >
                    <Td
                      className="table_number_cell"
                      style={{ textAlign: "center" }}
                    >
                      {proposta.numeroProposta}
                    </Td>
                    <Td className="table_number_cell">
                      {proposta.cliente[0].label}
                    </Td>
                    <Td className="table_number_cell">
                      {new Date(proposta.dataPrevisao).toLocaleDateString(
                        "pt-BR"
                      )}
                    </Td>
                    <Td className="table_number_cell">
                      {proposta.fase[0].label}
                    </Td>
                    <Td
                      className="table_number_cell"
                      style={{ textAlign: "right" }}
                    >
                      R$
                      {proposta.total.toLocaleString("pt-BR", {
                        style: "decimal",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Td>
                    <Td
                      className={css(styles.table_actions)}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <Tooltip title={"Visualizar"} arrow>
                        <RemoveRedEyeIcon
                          color="action"
                          fontSize="inherit"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleViewOrc(proposta.numeroProposta);
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                      <Tooltip title={"Exlcuir"} arrow>
                        <DeleteIcon
                          color="error"
                          fontSize="inherit"
                          onClick={() =>
                            handleDeleteOrcamento(proposta.numeroProposta)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </Td>
                  </Tr>
                </React.Fragment>
              ))}
            </Tbody>
          </Table>
        ) : null}

        {showSelectedOrc && propostaSelecionada !== null ? (
          <Box>
            <Card sx={{ boxShadow: 2, marginBottom: "25px", padding: "2px" }}>
              <CardContent
                sx={{
                  mt: -0.5,
                  padding: 0.5,
                  "&:last-child": {
                    paddingBottom: 0.25,
                  },
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                  style={{ marginTop: 10 }}
                >
                  <Typography variant="body2" fontWeight={"Bold"}>
                    Total de itens: {propostaAtual.itens.length}
                  </Typography>
                  <Typography variant="body2" fontWeight={"Bold"}>
                    Subtotal do pedido: R${" "}
                    {propostaAtual.total.toLocaleString("pt-BR", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  justifyContent={"left"}
                  alignItems={"left"}
                  width={"100%"}
                  style={{ marginTop: 10 }}
                >
                  <Typography variant="body2" fontWeight={"Bold"}>
                    Quantidade total:{" "}
                    {propostaAtual.itens.reduce(
                      (acc, item) => acc + parseFloat(item.quant || "0"),
                      0
                    )}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>

            <Table className={css(styles.table)}>
              <Thead className="table_head">
                <Tr>
                  <Th>Técnico</Th>
                  <Th>Código</Th>
                  <Th>Descrição</Th>
                  <Th>Tipo</Th>
                  <Th>Quant.</Th>
                  <Th>Preço</Th>
                  <Th>Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {propostaAtual.itens.map((item, subIndex) => (
                  <Tr
                    key={subIndex}
                    style={{
                      backgroundColor: subIndex % 2 === 0 ? "#fff" : "#f9f9f9",
                    }}
                  >
                    <Td className="table_number_cell">{item.tecnico}</Td>
                    <Td className="table_number_cell">{item.codigo}</Td>
                    <Td className="table_number_cell">{item.descricao}</Td>
                    <Td
                      className="table_number_cell"
                      style={{ textAlign: "center" }}
                    >
                      {item.tipo}
                    </Td>
                    <Td style={{ textAlign: "right" }}>{item.quant}</Td>
                    <Td style={{ textAlign: "right" }}>
                      R$ {parseFloat(item.preco).toFixed(2).replace(".", ",")}
                    </Td>
                    <Td
                      className={css(styles.table_actions)}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <Tooltip title={"Editar"} arrow>
                        <EditIcon
                          color="action"
                          fontSize="inherit"
                          onClick={() => editarLinhaOrcamento(subIndex)}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                      <Tooltip title={"Excluir"} arrow>
                        <DeleteIcon
                          color="error"
                          fontSize="inherit"
                          onClick={() => {}}
                          style={{ cursor: "pointer" }}
                        />
                      </Tooltip>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                marginTop: "20px",
              }}
            >
              <button onClick={cancelarProposta} className={css(styles.topBtn)}>
                Cancelar Edição
              </button>
            </div>
          </Box>
        ) : null}

        {loading ? (
          <Box
            width={"100%"}
            height={"45vh"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid
              item
              xs={12}
              md={12}
              textAlign={"center"}
              justifyContent={"center"}
              alignItems={"center"}
              bgcolor={"#ECF9F2"}
              borderRadius={5}
              padding={5}
              color={Colors.noResultFont}
            >
              <div display={"flex"} style={{ marginTop: "-0.5rem" }}>
                <CircularProgress color="success" />
              </div>
              <div display={"flex"}>
                <p
                  style={{
                    color: "#4b4b4b",
                    fontSize: 14,
                    marginBottom: "-1rem",
                  }}
                >
                  Buscando informações
                </p>
              </div>
            </Grid>
          </Box>
        ) : null}

        <ErroModal
          modalOpen={modalOpen}
          toggle={() => setModalOpen(false)}
          titleModal={titleModal}
          textModal={textModal}
          isTwoButtons={isTwoButtons}
          onConfirm={modalResponse} // Passe a função de callback para o modal aqui
        />
      </Box>
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    fontFamily: "Poppins !important",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    transitionDuration: "0.2s",
  },
  btn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 6,
    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    marginTop: "1.95rem",
    transitionDuration: "0.2s",
    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
  btnDisabled: {
    backgroundColor: "#B3B3B3",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#B3B3B3",
    borderRadius: 6,
    "@media (max-width: 375px)": {
      fontSize: 10, // Tamanho da fonte menor para telas pequenas
      padding: "0.3rem 0.5rem", // Padding ajustado se necessário
    },
  },
  erro: {
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
    marginTop: "1rem",
    marginBottom: "-0.5rem",
  },
  topBtn: {
    backgroundColor: "#669966",
    cursor: "pointer",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 6,
    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.5rem 0.5rem 0.5rem",
    transitionDuration: "0.2s",
    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
    "@media (max-width: 375px)": {
      fontSize: 10, // Tamanho da fonte menor para telas pequenas
      padding: "0.3rem 0.5rem", // Padding ajustado se necessário
    },
  },
});
