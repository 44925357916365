import { StyleSheet, css } from "aphrodite";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  TableContainer,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import axios from "axios";
import errorHandler from "../../../components/core/store/errorHandler";
import { headers } from "../../../components/core/store/localStorage";
import { FilterBusca } from "../../../components/filter/FilterBusca";
import {
  FasesMock,
  RepresentantesMock,
  rowsFases,
  TecnicosMock,
} from "../../../mock/FasesMock";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Colors from "../../../components/constants/Colors";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { IconButton } from "@mui/material";
import CustomDatagrid from "../../vendas/datagrid/CustomDatagrid";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { FilterTecs } from "../../../components/filter/FilterTecs";

const currentUser = localStorage.getItem("userName");
const currentEmp = localStorage.getItem("empName");
const API_URL = localStorage.getItem("apiUrl");

export const CadastroRepresentante = () => {
  const [head, setHead] = useState("");
  const [rows, setRows] = useState("");
  const [title, setTitle] = useState("");
  const [textModal, setTextModal] = useState("");
  const [clienteList, setClienteList] = useState([]);
  const [text, setText] = useState("");
  const [telaInicial, setTelaInicial] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [ativo, setAtivo] = useState(true);
  const [representantes, setRepresentantes] = useState(RepresentantesMock);

  useEffect(() => {
    getClientes();
    if (API_URL === null) {
      window.location.reload();
    }
  }, []);

  const getClientes = () => {
    axios
      .get(`${API_URL}/cliente`, headers())
      .then((response) => {
        const resp = response.data.clientes.map((res) => ({
          value: res.id,
          label: res.estabelecimentoId + " | " + res.nome,
        }));
        setClienteList(resp);
      })
      .catch((err) => {
        console.log(err);
        errorHandler(err);
      });
  };

  async function getRepresentantes() {
    setLoading(true);

    setTimeout(() => {
      setIsSearching(true);
      setLoading(false);
    }, 1500);

    // setHead(response.data.columns);
    // setRows(response.data.rows);
  }

  const consultarRepresentantes = () => {
    getRepresentantes();
    setIsSearching(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      {telaInicial ? (
        <Box sx={{ flexGrow: 1 }}>
          <Grid wrap="wrap">
            <Grid
              item
              xs={6}
              sm={7}
              md={8}
              lg={10}
              style={{
                marginTop: "-1.6rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3
                className={css(styles.pageTitle)}
                style={{ marginRight: "4px" }}
              >
                Representantes
              </h3>
              {/* <div style={{ display: "flex", gap: "15px" }}>
                <button
                  onClick={iniciarCadastro}
                  className={css(styles.topBtn)}
                >
                  Novo Técnico
                </button>
              </div> */}
            </Grid>
          </Grid>

          <hr
            style={{
              color: "#eee",
              marginTop: "-0.55rem",
              marginBottom: "1rem",
            }}
          />

          <FilterBusca
            text={text}
            setText={setText}
            search={consultarRepresentantes}
          />

          {loading ? (
            <Box
              width={"100%"}
              height={"45vh"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Grid
                item
                xs={12}
                md={12}
                textAlign={"center"}
                justifyContent={"center"}
                alignItems={"center"}
                bgcolor={"#ECF9F2"}
                borderRadius={5}
                padding={5}
                color={Colors.noResultFont}
              >
                <div
                  display={"flex"}
                  style={{
                    marginTop: "-0.5rem",
                  }}
                >
                  <CircularProgress color="success" />
                </div>
                <div display={"flex"}>
                  <p
                    style={{
                      color: "#4b4b4b",
                      fontSize: 14,
                      marginBottom: "-1rem",
                    }}
                  >
                    Buscando informações
                  </p>
                </div>
              </Grid>
            </Box>
          ) : null}

          {isSearching ? (
            <Table>
              <Thead className="table_head">
                <Tr>
                  <Th>ID</Th>
                  <Th style={{ textAlign: "left" }}>Nome</Th>
                </Tr>
              </Thead>
              <Tbody>
                {representantes.map((result, index) => (
                  <Tr
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#fff" : "#f9f9f9",
                    }}
                  >
                    <Td
                      className="table_number_cell"
                      style={{ textAlign: "center" }}
                    >
                      {result.id}
                    </Td>
                    <Td className="table_number_cell">
                      {result.nomeRepresentante}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : // <CustomDatagrid rows={rows} head={head} loading={loading} />
          null}
        </Box>
      ) : null}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "auto",
            minWidth: "300px",
            maxWidth: "800px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            {title}
          </Typography>

          <Typography id="modal-description" sx={{ mt: 2, mb: 3 }}>
            {textModal}
          </Typography>

          <Button
            variant="contained"
            sx={{
              backgroundColor: "green",
              "&:hover": {
                backgroundColor: "darkgreen",
              },
            }}
            onClick={handleCloseModal}
          >
            OK
          </Button>
        </Box>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  input: {
    fontFamily: "Poppins !important",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    transitionDuration: "0.2s",
  },
  btn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 6,
    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    marginTop: "1.95rem",
    transitionDuration: "0.2s",
    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
  btnDisabled: {
    backgroundColor: "#B3B3B3",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#B3B3B3",
    borderRadius: 6,
    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    marginTop: "1.95rem",
  },
  erro: {
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
    marginTop: "1rem",
    marginBottom: "-0.5rem",
  },
  topBtn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 6,
    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.5rem 0.5rem 0.5rem",
    transitionDuration: "0.2s",
    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
});
