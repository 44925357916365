import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import Chart from "react-google-charts";
import { OrcamentoContext } from "../OrcamentoContext";
import FilterAcomp from "../../../components/filter/FilterAcomp";
import { StyleSheet, css } from "aphrodite";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { OrcamentosMock } from "../../../mock/OrcamentosMock";

export const Acompanhamento = () => {
  const { lista } = useContext(OrcamentoContext);
  const [inicioInclusao, setInicioInclusao] = useState("");
  const [fimInclusao, setFimInclusao] = useState("");
  const [inicioPrevisao, setInicioPrevisao] = useState("");
  const [fimPrevisao, setFimPrevisao] = useState("");
  const [loading, setLoading] = useState(false);
  const [viewGraphic, setViewGraphic] = useState(false);
  const [fase, setFase] = useState([]);
  const [erro, setErro] = useState("");
  const [chartType, setChartType] = useState("BarChart");
  const [modalOpen, setModalOpen] = useState(false);
  const [chartDataToShow, setChartDataToShow] = useState([]);
  const [selectedFase, setSelectedFase] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [dadosTabela, setDadosTabela] = useState([]);

  const faseList = [
    { value: "comercial", label: "Comercial" },
    { value: "design", label: "Design" },
    { value: "gerar_proposta", label: "Gerar Proposta" },
    { value: "enviar_cliente", label: "Enviar p/ Cliente" },
  ];

  // TROCAR DEPOIS O MOCK POR LISTA

  const chartData = [
    ["Fase", "Quantidade de Propostas"],
    ...OrcamentosMock.reduce((acc, orcamento) => {
      const faseLabel = orcamento.fase[0]?.label || "Sem Fase";
      const propostaExistente = acc.find((item) => item[0] === faseLabel);

      if (!propostaExistente) {
        const quantidadePropostasUnicas = new Set(
          OrcamentosMock.filter(
            (item) => item.fase[0]?.label === faseLabel
          ).map((item) => item.numeroProposta)
        ).size;

        acc.push([faseLabel, quantidadePropostasUnicas]);
      }

      return acc;
    }, []),
  ];

  const filteredChartData = [
    ["Fase", "Quantidade de Propostas"],
    ...chartData
      .slice(1)
      .filter(
        ([label]) => fase.length === 0 || fase.some((f) => f.label === label)
      )
      .map(([label, value]) => [label, Number(value)]),
  ];

  // useEffect(() => {
  //     getPropostas();
  //     if (API_URL === null) {
  //       window.location.reload();
  //     }
  //   }, []);

  // const getPropostas = () => {
  //     axios
  //       .get(`${API_URL}/propostas`, headers())
  //       .then((response) => {
  //         const resp = response.data.clientes.map((res) => ({
  //           value: res.id,
  //           label: res.estabelecimentoId + " | " + res.nome,
  //         }));
  //         setClienteList(resp);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         errorHandler(err);
  //       });
  //   };

  const mostrarGrafico = () => {
    // Validação das datas
    if (
      (fimInclusao &&
        inicioInclusao &&
        new Date(inicioInclusao) > new Date(fimInclusao)) ||
      (fimPrevisao &&
        inicioPrevisao &&
        new Date(inicioPrevisao) > new Date(fimPrevisao))
    ) {
      setErro("A data de início não pode ser maior que a final.");
      setViewGraphic(false);
      return;
    }

    if (OrcamentosMock.length === 0) {
      setErro("Não há orçamentos cadastrados.");
      setViewGraphic(false);
      return;
    }

    const propostasFiltradas = OrcamentosMock.filter((proposta) => {
      if (
        fase.length > 0 &&
        !fase.some((f) => f.label === proposta.fase[0]?.label)
      ) {
        return false;
      }

      if (inicioInclusao || fimInclusao) {
        const dataTransmissao = new Date(proposta.dataTransmissao);
        const inicioInclusaoDate = inicioInclusao
          ? new Date(inicioInclusao)
          : null;
        const fimInclusaoDate = fimInclusao ? new Date(fimInclusao) : null;

        dataTransmissao.setHours(0, 0, 0, 0);
        if (inicioInclusaoDate) inicioInclusaoDate.setHours(0, 0, 0, 0);
        if (fimInclusaoDate) fimInclusaoDate.setHours(0, 0, 0, 0);

        if (inicioInclusaoDate && dataTransmissao < inicioInclusaoDate)
          return false;
        if (fimInclusaoDate && dataTransmissao > fimInclusaoDate) return false;
      }

      if (inicioPrevisao || fimPrevisao) {
        const dataPrevisao = new Date(proposta.dataPrevisao);
        const inicioPrevisaoDate = inicioPrevisao
          ? new Date(inicioPrevisao)
          : null;
        const fimPrevisaoDate = fimPrevisao ? new Date(fimPrevisao) : null;

        dataPrevisao.setHours(0, 0, 0, 0);
        if (inicioPrevisaoDate) inicioPrevisaoDate.setHours(0, 0, 0, 0);
        if (fimPrevisaoDate) fimPrevisaoDate.setHours(0, 0, 0, 0);

        if (inicioPrevisaoDate && dataPrevisao < inicioPrevisaoDate)
          return false;
        if (fimPrevisaoDate && dataPrevisao > fimPrevisaoDate) return false;
      }

      return true;
    });

    if (propostasFiltradas.length === 0) {
      setErro("Nenhum orçamento encontrado com os filtros aplicados.");
      setViewGraphic(false);
      return;
    }

    setErro("");
    setViewGraphic(true);

    const chartData = [
      ["Fase", "Quantidade de Propostas"],
      ...propostasFiltradas.reduce((acc, orcamento) => {
        const faseLabel = orcamento.fase[0]?.label || "Sem Fase";
        const propostaExistente = acc.find((item) => item[0] === faseLabel);

        if (!propostaExistente) {
          const quantidadePropostasUnicas = new Set(
            propostasFiltradas
              .filter((item) => item.fase[0]?.label === faseLabel)
              .map((item) => item.numeroProposta)
          ).size;

          acc.push([faseLabel, quantidadePropostasUnicas]);
        }

        return acc;
      }, []),
    ];

    setChartDataToShow(chartData);

    const propostasUnicasAtualizadas = propostasFiltradas.reduce(
      (acc, orcamento) => {
        const propostaExistente = acc.find(
          (p) => p.numeroProposta === orcamento.numeroProposta
        );

        if (!propostaExistente) {
          acc.push({
            numeroProposta: orcamento.numeroProposta,
            cliente: orcamento.cliente,
            dataPrevisao: orcamento.dataPrevisao,
            dataTransmissao: orcamento.dataTransmissao,
            fase: orcamento.fase,
            total: orcamento.itens.reduce(
              (total, item) => total + item.quant * item.preco,
              0
            ),
          });
        }

        return acc;
      },
      []
    );

    setDadosTabela(propostasUnicasAtualizadas);
  };

  const updateChartType = (e) => {
    setChartType(e.target.value);
  };

  // const handleChartClick = ({ chartWrapper }) => {
  //   const chart = chartWrapper.getChart();
  //   const selection = chart.getSelection();

  //   if (selection.length > 0) {
  //     const selectedRow = selection[0].row;
  //     const selectedFase = chartDataToShow[selectedRow + 1][0];

  //     const dadosFiltrados = OrcamentosMock.filter(
  //       (item) => item.fase[0]?.label === selectedFase
  //     );

  //     setSelectedFase(selectedFase);
  //     setFilteredData(dadosFiltrados);
  //     setModalOpen(true);
  //   }
  // };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const getFirstDayOfMonth = () => {
    const date = new Date();
    return new Date(date.getFullYear(), date.getMonth(), 1);
  };

  const getCurrentDate = () => {
    return new Date();
  };

  // Para DatePickers que aceitam objetos Date diretamente:
  useEffect(() => {
    const firstDayOfMonth = getFirstDayOfMonth();
    const currentDate = getCurrentDate();

    setInicioInclusao(firstDayOfMonth);
    // setFimInclusao(currentDate);
    setInicioPrevisao(firstDayOfMonth);
    // setFimPrevisao(currentDate);
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid wrap="wrap">
          <Grid
            item
            xs={6}
            sm={7}
            md={8}
            lg={10}
            style={{
              marginTop: "-1.6rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 className={css(styles.pageTitle)}>
              Distribuição das Propostas
            </h3>{" "}
          </Grid>
        </Grid>
        <hr
          style={{ color: "#eee", marginTop: "-0.55rem", marginBottom: "1rem" }}
        />
        <FilterAcomp
          inicioInclusao={inicioInclusao}
          setInicioInclusao={setInicioInclusao}
          fimInclusao={fimInclusao}
          setFimInclusao={setFimInclusao}
          inicioPrevisao={inicioPrevisao}
          setInicioPrevisao={setInicioPrevisao}
          fimPrevisao={fimPrevisao}
          setFimPrevisao={setFimPrevisao}
          faseList={faseList}
          loading={loading}
          erro={erro}
          mostrarGrafico={mostrarGrafico}
          fase={fase}
          setFase={setFase}
        />

        {viewGraphic && chartDataToShow.length > 1 ? (
          <div>
            <div
              style={{
                borderRadius: 10,
                boxShadow: "0px 2px 6px -1px rgba(0,0,0,0.2)",
                marginBottom: "1rem",
              }}
            >
              <Grid item xs={12} sm={4} md={4}>
                <FormControl
                  variant="standard"
                  sx={{
                    minWidth: 150,
                    marginTop: 1.5,
                    marginLeft: 2,
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#669966",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#669966",
                    },
                    "&:hover .MuiInput-underline:before": {
                      borderBottomColor: "#669966",
                    },
                  }}
                >
                  <InputLabel
                    id="tipos"
                    sx={{
                      fontSize: 10,
                    }}
                  >
                    Tipo de gráfico
                  </InputLabel>
                  <Select
                    labelId="tipos"
                    placeholder="Tipo de gráfico"
                    name="type"
                    isMulti
                    onChange={(ev) => updateChartType(ev)}
                    sx={{
                      color: "#4b4b4b",
                      fontSize: 12,
                    }}
                    noOptionsMessage={() => "Sem resultados"}
                    defaultValue=""
                  >
                    <MenuItem
                      value={"BarChart"}
                      sx={{
                        color: "#4b4b4b",
                        fontSize: 12,
                      }}
                    >
                      Gráfico de barras horizontal
                    </MenuItem>
                    <MenuItem
                      value={"ColumnChart"}
                      sx={{
                        color: "#4b4b4b",
                        fontSize: 12,
                      }}
                    >
                      Gráfico de barras vertical
                    </MenuItem>
                    <MenuItem
                      value={"LineChart"}
                      disabled={chartDataToShow.length > 1 ? false : true}
                      sx={{
                        color: "#4b4b4b",
                        fontSize: 12,
                      }}
                    >
                      Gráfico de linhas
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Chart
                chartType={chartType}
                width="100%"
                height="400px"
                data={chartDataToShow}
                options={{
                  title: "",
                  chartArea: { width: "80%" },
                  hAxis: {
                    title: "Quantidade de Propostas",
                    minValue: 0,
                    format: "0",
                  },
                  vAxis: {
                    title: "Fases",
                    format: "0",
                  },
                  legend: { position: "none" },
                  colors: ["#669966"],
                }}
                // chartEvents={[
                //   {
                //     eventName: "select",
                //     callback: handleChartClick,
                //   },
                // ]}
              />
            </div>
            <Table className={css(styles.table)}>
              <Thead className="table_head">
                <Tr>
                  <Th>Nº Proposta</Th>
                  <Th style={{ textAlign: "left" }}>Cliente</Th>
                  <Th style={{ textAlign: "left" }}>Data Previsão</Th>
                  <Th style={{ textAlign: "left" }}>Fase</Th>
                  <Th style={{ textAlign: "right" }}>Total</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dadosTabela.map((proposta, index) => (
                  <Tr
                    key={index}
                    className="table"
                    style={{
                      backgroundColor: index % 2 === 0 ? "#fff" : "#f9f9f9",
                    }}
                  >
                    <Td style={{ textAlign: "center" }}>
                      {proposta.numeroProposta}
                    </Td>
                    <Td>{proposta.cliente[0].label}</Td>
                    <Td>
                      {new Date(proposta.dataPrevisao).toLocaleDateString(
                        "pt-BR"
                      )}
                    </Td>
                    <Td>{proposta.fase[0].label}</Td>
                    <Td style={{ textAlign: "right" }}>
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(proposta.total)}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>{erro}</div>
        )}

        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: "800px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2">
              Dados da Fase: {selectedFase}
            </Typography>

            <Table className={css(styles.table)}>
              <Thead className="table_head">
                <Tr>
                  <Th>Nº Proposta</Th>
                  <Th>Cliente</Th>
                  <Th>Data Previsão</Th>
                  <Th>Fase</Th>
                  <Th>Total</Th>
                </Tr>
              </Thead>

              <Tbody>
                {filteredData
                  .filter((item) => item.fase[0]?.label === selectedFase) // Filtra pela fase selecionada
                  .reduce((acc, orcamento) => {
                    console.log(filteredData);
                    const propostaExistente = acc.find(
                      (p) => p.numeroProposta === orcamento.numeroProposta
                    );

                    if (!propostaExistente) {
                      acc.push({
                        numeroProposta: orcamento.numeroProposta,
                        cliente: orcamento.cliente,
                        dataPrevisao: orcamento.dataPrevisao,
                        fase: orcamento.fase,
                        // total: filteredData
                        //   .filter(
                        //     (orc) =>
                        //       orc.numeroProposta === orcamento.numeroProposta
                        //   )
                        //   .reduce(
                        //     (total, item) => total + item.quant * item.preco,
                        //     0
                        //   ),
                        total: orcamento.itens.reduce(
                          (total, item) => total + item.quant * item.preco,
                          0
                        ),
                      });
                    }
                    return acc;
                  }, [])
                  .map((proposta, index) => (
                    <Tr
                      key={index}
                      className="table"
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fff" : "#f9f9f9",
                      }}
                    >
                      <Td
                        className="table_number_cell"
                        style={{ textAlign: "center" }}
                      >
                        {proposta.numeroProposta}
                      </Td>
                      <Td className="table_number_cell">
                        {proposta.cliente[0].label}
                      </Td>
                      <Td className="table_number_cell">
                        {new Date(proposta.dataPrevisao).toLocaleDateString(
                          "pt-BR"
                        )}
                      </Td>
                      <Td className="table_number_cell">
                        {proposta.fase[0].label}
                      </Td>
                      {/* <Td
                        className="table_number_cell"
                        style={{ textAlign: "right" }}
                      >
                        R$ {proposta.total.toFixed(2).replace(".", ",")}
                      </Td> */}
                      <Td
                        className="table_number_cell"
                        style={{ textAlign: "right" }}
                      >
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(proposta.total)}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

const styles = StyleSheet.create({
  table: {
    width: "100%",
    borderCollapse: "separate",
    borderSpacing: 0,
    borderRadius: "8px", // Adiciona border-radius
    overflow: "hidden", // Garante que o border-radius funcione
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Adiciona box-shadow
  },
  table_head: {
    backgroundColor: "#f5f5f5", // Cor de fundo do cabeçalho
  },
  table_actions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
  },
});
